<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card :title="$t('Checkk')">
          <b-row
            v-if="data"
          >
            <b-col
              v-for="el in ['no','No','fee','platee','chassis_number', 'chassis_number_type','owner_type1', 'created_year', 'first_registeraion',
                            'cabin_number','cabin_number_type','motor_number_type','motor_reserve_number','motor_reserve_number_type',
                            'motor_number','cabin_reserve_number_type','cabin_reserve_number','expiration_date',
                            'color','fuel_type1','car_type','motor_model','motor_manufacturer'
                            ,'notes']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="data[el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-card :title="$t('owwner applicant')">
          <b-row
            v-if="data.owwner"
          >
            <b-col
              v-for="el in ['id','owner', 'applicant','date_time', 'phone', 'identify']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="data.owwner[el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >

        <b-card>
          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('color') }}</label>
                <b-form-input
                  v-model="data.color"
                  class="mt-2"
                  :state="errors.color ?false:null"
                  :placeholder="$t('color')"
                />
                <small
                  v-if="errors.color"
                  class="text-danger"
                >{{ errors.color[0] }}</small>
                <label>{{ $t('color') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('fuel_type') }}</label>
                <b-form-select
                  v-model="data.fuel_type"
                  :options="fueltypeoptions"
                />
                <small
                  v-if="errors.fuel_type"
                  class="text-danger"
                >{{ errors.fuel_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Expiration_date') }}</label>
                <b-form-datepicker
                  v-model="data.expiration_date"
                  :placeholder="$t('Expiration_date')"
                  :state="errors.expiration_date ?false:null"
                />
                <small
                  v-if="errors.expiration_date"
                  class="text-danger"
                >{{ errors.expiration_date[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.other_fee"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('other_fee')"
                  :state="errors.other_fee ?false:null"
                  :class="errors.other_fee ?'is-invalid':null"
                />
                <small
                  v-if="errors.other_fee"
                  class="text-danger"
                >{{ errors.other_fee[0] }}</small>
                <label>{{ $t('other_fee') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="form-label-group">
                <label>{{ $t('notes') }}</label>
                <b-form-input
                  v-model="data.notes"
                  class="mt-2"
                  :state="errors.notes ?false:null"
                  :placeholder="$t('notes')"
                />
                <small
                  v-if="errors.notes"
                  class="text-danger"
                >{{ errors.notes[0] }}</small>
                <label>{{ $t('notes') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('public/specific') }}</label>
                <b-form-checkbox
                  v-model="data.owner_type"
                  :value="true"
                  :state="errors.owner_type ?false:null"
                >
                  {{ data.owner_type ? $t('specific') : $t('public') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.owner_type"
                class="text-danger"
              >{{ errors.owner_type[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('brake') }}</label>
                <b-form-checkbox
                  v-model="data.brake"
                  :value="true"
                  :state="errors.brake ?false:null"
                >
                  {{ data.brake ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.brake"
                class="text-danger"
              >{{ errors.brake[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('light') }}</label>
                <b-form-checkbox
                  v-model="data.light"
                  :value="true"
                  :state="errors.light ?false:null"
                >
                  {{ data.light ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.light"
                class="text-danger"
              >{{ errors.light[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('combustion') }}</label>
                <b-form-checkbox
                  v-model="data.combustion"
                  :value="true"
                  :state="errors.combustion ?false:null"
                >
                  {{ data.combustion ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.combustion"
                class="text-danger"
              >{{ errors.combustion[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('wheel') }}</label>
                <b-form-checkbox
                  v-model="data.wheel"
                  :value="true"
                  :state="errors.wheel ?false:null"
                >
                  {{ data.wheel ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.wheel"
                class="text-danger"
              >{{ errors.wheel[0] }}</small>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            v-if="haveAccess(['Set Company'])"
            cols="12"
          >
            <b-card :title="$t('Company')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.company_id"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company_id"
                class="text-danger"
              >{{ errors.company_id[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            v-if="haveAccess(['Company Other'])"
            cols="12"
          >
            <b-card :title="$t('Company Fee Belong To')">
              <label>{{ $t('please select first ...') }}</label>
              <v-select
                v-model="data.company_from"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company_from"
                class="text-danger"
              >{{ errors.company_from[0] }}</small>
              <label>{{ $t('please select second...') }}</label>
              <v-select
                v-model="data.company_to"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company_to"
                class="text-danger"
              >{{ errors.company_to[0] }}</small>
            </b-card>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-card :title="$t('invoice')">
              {{ $t('renew_fee') }} : {{ this.data.renew_fee }}$<br>
              {{ $t('other_fee') }} : {{ this.data.other_fee }}$<br>
              {{ $t('Total') }} : {{ this.data.renew_fee }} + {{ this.data.other_fee }} ={{ parseFloat(this.data.renew_fee) + parseFloat(this.data.other_fee) }}$<br>
              {{ $t('Total') }} : {{ Total }}<span>&#8378;</span><br>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
      <b-col
        cols="8"
        md="8"
      />
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="update"
                  >
                    {{ $t('Update') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeoptions: [
        { value: 'Normal', text: 'نظامية' },
        { value: 'Assa', text: 'تجميع' },
      ],
      fueltypeoptions: [
        { value: 'mazot', text: 'مازوت' },
        { value: 'benzin', text: 'بنزين' },
        { value: 'gaz', text: 'غاز' },
      ],
      data: {
        renewData: 'renew',
        fee: 0,
        renew_fee: 0,
        other_fee: 0,
        total: 0,
      },
      errors: [],
      fileErrors: '',
    }
  },
  computed: {
    Total() {
      let totalfee = 0
      totalfee = (parseFloat(this.data.renew_fee) + parseFloat(this.data.other_fee)) * parseFloat(this.Tolls().dollar)
      return totalfee
    },
    Element() { return this.$store.getters['renew/GetElement'] },
    Companies() {
      return this.$store.getters['company/GetElements']
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.chassis_number': function (newEl) {
      const year = this.created_year[newEl.slice(10, 11)]
      if (year) {
        this.data.created_year = year
      }
    },
    'data.owwner_data': function () {
      this.data.owner = this.data.owwner_data
      this.data.owwner = this.data.owwner_data
    },
    'data.other_fee': function (val) {
      if (!val) {
        this.data.other_fee = 0
      }
    },
    'data.renew': function (val) {
      if (val) {
        this.data.fee = 0
        if (this.data.created_year > 1900) {
          if (this.data.created_year < 2006) {
            if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 1600) {
              this.data.fee = (parseFloat(this.Tolls.tourismOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourismOneRenew)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourismTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourismTwoRenew)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power > 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourismThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourismThreeRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.transportOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transportOneRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.transportTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transportTwoRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.transportThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transportThreeRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.spicialOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialOneRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialTwoRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialThreeRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 14) {
              this.data.fee = (parseFloat(this.Tolls.busOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.busOneRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 30) {
              this.data.fee = (parseFloat(this.Tolls.busTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.busTwoRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger > 30) {
              this.data.fee = (parseFloat(this.Tolls.busThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.busThreeRenew)
            } else if (this.data.carr.car_type === 'goverment') {
              this.data.fee = (parseFloat(this.Tolls.govermentOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.govermentOneRenew)
            } else if (this.data.carr.car_type === 'motor') {
              this.data.fee = (parseFloat(this.Tolls.motorOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.motorOneRenew)
            }
          } else if (this.data.created_year > 2005) {
            if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 1600) {
              this.data.fee = (parseFloat(this.Tolls.tourism2OneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourism2OneRenew)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourism2TwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourism2TwoRenew)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power > 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourism2ThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.tourism2ThreeRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.transport2OneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transport2OneRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.transport2TwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transport2TwoRenew)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.transport2ThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.transport2ThreeRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.spicialOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialOneRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialTwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialTwoRenew)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.spicialThreeRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 14) {
              this.data.fee = (parseFloat(this.Tolls.bus2OneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.bus2OneRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 30) {
              this.data.fee = (parseFloat(this.Tolls.bus2TwoRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.bus2TwoRenew)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger > 30) {
              this.data.fee = (parseFloat(this.Tolls.bus2ThreeRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.bus2ThreeRenew)
            } else if (this.data.carr.car_type === 'goverment') {
              this.data.fee = (parseFloat(this.Tolls.govermentOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.govermentOneRenew)
            } else if (this.data.carr.car_type === 'motor') {
              this.data.fee = (parseFloat(this.Tolls.motorOneRenew)) * parseFloat(this.Tolls.dollar)
              this.data.renew_fee = parseFloat(this.Tolls.motorOneRenew)
            }
          }
        }
      } else {
        this.clearData()
      }
    },
  },
  created() {
    this.$store.dispatch('owwner/GetAvailableElements')
    this.$store.dispatch('platee/GetAvailableElements')
    this.$store.dispatch('carr/GetElements')
    this.$store.dispatch('company/GetElements')
    this.$store.dispatch('toll/GetPluckedElements')
    this.getData()
  },
  methods: {
    SelectedCar(val) {
      return this.Carrs.find(el => el.id === val)
    },
    SelectedOwner(val) {
      return this.Owwners.find(el => el.id === val.id)
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    Platees() {
      return this.$store.getters['platee/GetElements']
    },
    Checkk() {
      return this.$store.getters['checkk/GetAvailableElements']
    },
    Owwners() { return this.$store.getters['owwner/GetElements'] },
    Tolls() { return this.$store.getters['toll/GetElements'] },

    Carrs() { return this.$store.getters['carr/GetElements'] },
    getData() {
      this.$store.dispatch('renew/GetElement', this.$route.params.renew).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.data.fee = parseInt(this.Total, 10)
      this.data.owwner = this.data.owwner_data.id
      this.errors = []
      this.$store.dispatch('renew/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
